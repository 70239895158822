.dashcounters {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mb-4;
}

.dashcounter {
  @apply bg-gray-200 dark:bg-gray-800 p-4 rounded flex flex-col items-center space-y-2 hover:-translate-y-1 transition-transform cursor-pointer;
}

.dashwidgets {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0 20px 20px 20px;
}

.dashwidget {
  flex: 1;
  margin-bottom: 20px;
  padding: 0 5px;

  h4 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: hsla(var(--primary-text-color_hsl), 0.6);
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--accent-color--med);
  }

  a {
    color: var(--brand-color);
  }
}

.unapproved-account {
  padding: 15px 20px;
  font-size: 14px;
  display: flex;

  &__nickname {
    font-weight: bold;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
    column-gap: 10px;
    padding-left: 20px;

    .svg-icon {
      height: 24px;
      width: 24px;
    }
  }
}

.logentry {
  padding: 15px;

  &__timestamp {
    color: var(--primary-text-color--faint);
    font-size: 13px;
    text-align: right;
  }
}
